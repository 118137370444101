import { Link } from 'react-router-dom'
// import style from './CartItem.module.scss'
import { capFirst, fColor } from '../../utils/string'
import { ACCOUNT_PATH } from '../../router/paths'
import ProductImageThumbnail from '../ProductImageThumbnail/ProductImageThumbnail'
import { formatMoney } from '../../utils/date-time'

const CartItem = ({ parentId, cartItem, hide, remove }) => {
  const template = {
    ...cartItem,
    backgroundImage: cartItem?.backgroundImageUrl,
    graphicImage: cartItem?.graphicImageUrl,
    backBackgroundImage: cartItem?.backBackgroundImageUrl,
    backGraphicImage: cartItem?.backGraphicImageUrl,
  }

  return (
    <div className='bag-item' key={cartItem.id}>
      <ProductImageThumbnail template={template} />
      <div className='item-detail'>
        <div className='item-price'>
          <span className='regular-price'>${formatMoney(cartItem?.price) || 'NA'}</span>
          {cartItem?.price < cartItem?.oldPrice && (
            <span className='discount-price text-decoration-line-through'>
              ${formatMoney(cartItem?.oldPrice) || 'NA'}
            </span>
          )}
        </div>
        <div className='detail'>
          <div className='item-name'>
            <h6>
              {cartItem?.altCode} - {cartItem?.b2bWebTitle}
            </h6>
            <div className='color'>Color: {capFirst(fColor(cartItem.bodyColorName))}</div>
          </div>
          <div className='quantity'>
            <p>Quantity: {cartItem.qty}</p>
            <p>
              {cartItem.sizeScaleName} - {cartItem.sizeScale} (XS-XXL)
            </p>
          </div>
          <div className='cart-action'>
            <ul className='action-list'>
              <li>
                <Link
                  onClick={() => hide()}
                  to={ACCOUNT_PATH.detail(
                    cartItem.style,
                    parentId,
                    cartItem.childId,
                    cartItem.templateId,
                  )}
                  className='d-inline-block'
                >
                  <button type='button' className={'btn btn-link p-0 border-0'}>
                    Add Color
                  </button>
                </Link>
              </li>
              <li>
                <button
                  type={'button'}
                  disabled={cartItem.isDummy}
                  onClick={() => remove(cartItem.id)}
                  className={'btn btn-link p-0 border-0'}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem
