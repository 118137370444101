/* eslint-disable no-unused-vars */
import client from '../utils/axios'
import { getQueryString } from '../utils/url'


export const fetchInventoryCategory = () => {
  return client.get('/api/inventory/categories')
}
export const fetchStyleCategory = () => {
  return client.get('/api/inventory/style-categories')
}
export const fetchAltStyles = () => {
  return client.get('/api/inventory/alt-styles')
}

export const fetchAltStyleDetail = (fieldName, fieldValue) => {
  return client.get
    (`/api/inventory/alt-style-detail?fieldName=${fieldName}&fieldValue=${fieldValue}`)
}
export const fetchBlankStyleFitbyName = ({ name }) => {
  return client.get(`/api/inventory/blank-style-fit-by-name/${name}`)
}


export const fetchTemplates = ({ childId, ...queryParams }) => {
  const queryString = getQueryString(queryParams)
  return client.get(`/api/childAccount/${childId}/templates?${queryString}`)
}

export const fetchTemplateById = ({ templateId }) => {
  return client.get(`/api/templates/template-by-id/${templateId}`)
}

export const fetchColorsByTemplateId = ({ templateId, category, design }) => {
  return client.get(`/api/templateColor/${templateId}`, { category, design })
}

export const fetchPricesByTemplateId = ({ templateId, childId = '' }) => {
  return client.get(`/api/templatePrice/templateId/${templateId}?childId=${childId}`)
}

export const fetchSizeScales = (queryParams = {}) => {
  const query = getQueryString(queryParams)
  return client.get('/api/custom-size/list?' + query)
}

export const fetchSizeScaleById = ({ id }) => {
  return client.get(`/api/custom-size/${id}`)
}

export const createSizeScale = (payload) => {
  return client.post('/api/custom-size/save', payload)
}

export const updateSizeScaleById = ({ id, ...payload }) => {
  return client.put(`/api/custom-size/${id}`, payload)
}

export const deleteSizeScaleById = ({ id }) => {
  return client.delete(`/api/custom-size/${id}`)
}

export const fetchLayers = ({ childId, templateId }) => {
  return client.post('/api/templates/query', { childId, templateId })
}

export const fetchDefaultColor = ({ childId, templateId }) => {
  return client.get(`api/templateDefault/${childId}/${templateId}`)
}


export const fetchTemplateGraphicRuleColors = () => {
  return client.get('/api/graphic-rules/colors/list')
}

export const fetchTemplateGraphicRules = (templateId, customerId) => {
  return client.get('/api/graphic-rules', { params: { customerId, templateId } })
}

export const fetchTemplateBodyColorMap = () => {
  return client.get('/api/body-color-map')
}

export const fetchBlankGramentInventory = (productId, colorAbbr) => {
  return client.get(`/api/inventory/local-blank-garments-inventory/${productId}`, {
    params: { colorAbbr }
  })
}




