import { encryptWithAES } from '../utils/AEShelper'
import client from '../utils/axios'

export const checkEmail = ({ email }) => {
  return client.post('/api/users/check-email', {
    email,
  })
}

export const login = ({ email, password }) => {
  return client.post('/api/users/login', {
    email,
    password,
  })
}

export const logoutService = () => {
  return client.post('/api/users/logout')
}

export const fetchProfileByEmail = ({ email }) => {
  return client.post('/api/users', {
    email,
  })
}

export const fetchProfileMe = () => {
  return client.get('/api/users/me')
}

export const forgotPassword = ({ email }) => {
  return client.put('/api/users/forgot-password', {
    email: email,
    requestedBy: 'website',
  })
}

export const changePassword = ({ password, accessToken }) => {
  return client.put('/api/users/change-password', {
    newPassword: password,
    accessToken,
  })
}

export const sendResetPasswordLinkToEmail = ({ email }) => {
  return client.post('/api/users/email-reset-password-link', { email })
}

export const resetPassword = async ({ token, password }) => {
  const encryptedData = await encryptWithAES(JSON.stringify({ token, password }))
  return client.put('/api/users/reset-password', { encryptedData })
}

export const updateUserProfile = ({ userId, payload }) => {
  return client.put(`/api/users/update/${userId}`, payload)
}

export const getBuyers = ({ parentId }) => {
  return client.get('/api/users/buyers', {
    params: {
      parentId,
    },
  })
}

export const register = ({ name, email, phone, role, parentId }) => {
  return client.post('/api/users/register', {
    name,
    email,
    phone,
    role,
    parentId,
  })
}

export const addBuyer = (payload) => {
  return client.post('/api/users/buyers', payload)
}

export const updateBuyer = ({ id, payload, parentId }) => {
  return client.put(`/api/users/buyers/${id}?parentId=${parentId}`, payload)
}

export const removeBuyer = (id) => {
  return client.put(`/api/users/remove/${id}`)
}

export const registrationRequest = (payload) => {
  return client.post('/api/users/registration-request', payload)
}
