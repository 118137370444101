/* eslint-disable no-undef */
export const NODE_ENV = process.env.NODE_ENV
export const ELEMENTOR_URL = process.env.REACT_APP_ELEMENTOR_URL
export const BASE_URL = process.env.REACT_APP_MOCKS ? '' : process.env.REACT_APP_BASE_URL
export const AWS_ASSETS_URL = process.env.REACT_APP_AWS_ASSETS_URL
export const STRIPE_LIVE_KEY = process.env.REACT_APP_STRIPE_LIVE_KEY
export const STRIPE_TEST_KEY = process.env.REACT_APP_STRIPE_TEST_KEY
export const MOCKS = process.env.REACT_APP_MOCKS
export const ENCRIPTING_KEY = process.env.REACT_APP_ENCRIPTING_KEY || ''
export const ENCRIPTING_IV = process.env.REACT_APP_ENCRIPTING_IV || ''

